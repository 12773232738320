<template>
  <div class="mt-0">
    <b-card header-tag="header" class="mx-auto" style="width: 80%">
      <b-row>
        <b-col>
          <h3>
            👏🏼 Félicitation, votre réservation a été soumise avec succès. ✈️
          </h3>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <h5>Groupe: {{ basicInformation.name }}</h5>
          <h5>
            Numéro de réservation: #{{ currentReservation.reservation_number }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="1">
                <b-avatar circle variant="success" size="60" class="mb-1">
                  <feather-icon icon="CheckIcon" size="40" />
                </b-avatar>
              </b-col>
              <b-col v-if="paymentDetails && paymentDetails.amount">
                <h3>Confirmation du paiement</h3>
                <b-card-text>
                  Votre confirmation de paiement sera envoyée à votre adresse
                  courriel.
                </b-card-text>
                <div>Montant chargé: <strong>{{ paymentDetails.amount / 100 }}$</strong></div>
                <div>Numéro de confirmation: <strong>{{ paymentDetails.id }}</strong></div>
              </b-col>
              <b-col v-else>
                <h3>Confirmation de la réservation</h3>
                <b-card-text>
                  Un agent prendra bientôt contact avec vous pour le paiement de
                  votre réservation.
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            size="sm"
            @click="$router.push({ path: returnURL })"
            variant="outline-info"
            >Retourner au sommaire de groupe</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BAlert,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BSpinner,
} from "bootstrap-vue";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { computed, ref, onMounted, reactive } from "@vue/composition-api";
import { useGroupApi } from "@/modules/group/composables/use-group-api";

import GroupReservationPayment from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationPayment.vue";
import { useReservationApi } from "@/modules/reservation/composables/use-reservation-api";
import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
export const groupReservationService = new GroupReservationService();
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";

export default {
  components: {
    BTabs,
    BAlert,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BSpinner,
    GroupReservationPayment,
    BAvatar,
  },
  props: ["currentPackages", "currentAgent", "hasExceededInventory", "errors"],
  setup(props, ctx) {
    const { forceUpdate, store } = useApplicationContext();

    let stripe_key = null;
    let payment_processor = ref(null);
    let monerisReceipt = reactive({});

    try {
      let orgSettings = store.state[AUTH_STORE_NAMESPACE].orgSettings;

      if (orgSettings) {
        stripe_key = orgSettings.stripe_publishable_key;
        payment_processor = orgSettings.payment_processor;
      }
    } catch (e) {
      console.error("Missing Org Settings Configured", e);
    }

    const stripe = Stripe(stripe_key);

    const { getGroupBasicInformation } = useGroupApi();
    let paymentDetails = ref({});

    const { createPaymentRegister, getMonerisCheckoutReceipt } =
      useReservationApi();

    let route = ctx.root.$route;
    const publicAccessToken = ctx.root.$route.query.token;

    let payment_intent_client_secret = route.query.payment_intent_client_secret;
    let currentGroupId = route.params.id;
    let currentReservationId = route.params.reservation_id;
    let currentReservation = ref({});

    let basicInformation = reactive({});

    const getPaymentIntent = async () => {
      stripe
        .retrievePaymentIntent(payment_intent_client_secret)
        .then(async function (result) {
          // Handle result.error or result.paymentIntent
          paymentDetails.value = result.paymentIntent;
          const response = await createPaymentRegister(
            result.paymentIntent,
            currentGroupId,
            currentReservationId,
            publicAccessToken
          );

          await groupReservationService.updateStatus(
            currentGroupId,
            currentReservationId,
            { status: "SUBMITTED" },
            publicAccessToken
          );
        });
    };

    const fetchGroupBasicInformation = async () => {
      try {
        const retrievedGroupBasicInfo = await getGroupBasicInformation(
          currentGroupId,
          publicAccessToken
        );
        Object.assign(basicInformation, retrievedGroupBasicInfo);
        forceUpdate();
      } catch (e) {}
    };

    const fetchReservation = async () => {
      try {
        const response = await groupReservationService.getReservation(
          currentGroupId,
          currentReservationId,
          publicAccessToken
        );
        currentReservation.value = response.data;
        forceUpdate();
      } catch (e) {}
    };

    const getMonerisReceipt = async (ticket_id) => {
      let monerisResponse = await getMonerisCheckoutReceipt(
        currentGroupId,
        currentReservationId,
        ticket_id,
        publicAccessToken
      );

      paymentDetails.value = monerisResponse.data;
    };

    onMounted(() => {
      fetchGroupBasicInformation(props.currentGroupId);
      fetchReservation();

      if (payment_processor === "moneris") {
        getMonerisReceipt(route.query.monerisTicket, publicAccessToken);
      } else if (payment_processor === "stripe") {
        getPaymentIntent();
      }
    });

    let returnURL = `/groups/${currentGroupId}`;
    if (publicAccessToken) {
      returnURL = `/public${returnURL}?token=${publicAccessToken}`;
    }

    return {
      paymentDetails,
      basicInformation,
      currentReservation,
      returnURL,
      payment_processor,
      monerisReceipt,
    };
  },
};
</script>

<style>
.pink {
  color: #f78b99;
}

.blue {
  color: #5bbaea;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
